import React, { createContext, Component } from 'react';

export const LanguageContext = createContext()

class LanguageContextProvider extends Component {

  state = { 
    current: "de",
    de: { 
      title: "DVGW Titel",
      home_title: "Hauptmenü",
      button_about: "DVGW\nim Porträt",
      button_activities: "Was wir tun",
      button_water: "Wasser",
      button_gas: "Gas",
      button_toggle_language: "English",
      button_toggle_theme: "9:16",
      title_about: "DVGW im Porträt",
      button_membership: "Mitgliedschaft",
      button_groups: "Landesgruppen",
      button_institutes: "Forschungsinstitute",
      button_youngprofessionals: "Junger DVGW",
      text_about_intro: "Der <strong>DVGW Deutscher Verein des Gas- und Wasserfaches e.V.</strong> fördert die Gas- und Wasserbranche mit den Schwerpunkten Sicherheit, Hygiene und Umweltschutz.",
      text_about_card1: "<img src='./dvgw-icon-members.svg'/><h5>rund <span>14.000</span> Mitglieder</h5>",
      text_about_card2: "<img src='./dvgw-icon-network.svg'/><h5>Gründung <span>1859</span> in<br/>Frankfurt am Main</h5>",
      text_about_card3: "<img src='./dvgw-icon-gas.svg'/><h5><span>91%</span> der Gasnetzbetreiber<br/>sind DVGW Mitglieder</h5>",
      text_about_card4: "<img src='./dvgw-icon-drinkingwater.svg'/><h5><span>73%</span> des Trinkwassers wird von<br/>DVGW Mitgliedern bereit gestellt</h5>",
      title_about_membership1: "Mitglieder des DVGW",
      image_members_card1: "../../dvgw-icon-members-1.svg",
      text_members_card1: "Versorgungsunternehmen",
      number_members_card1: "2100",
      image_members_card2: "../../dvgw-icon-members-2.svg",
      text_members_card2: "Unternehmen",
      number_members_card2: "1400",
      image_members_card3: "../../dvgw-icon-members-3.svg",
      text_members_card3: "Behörden",
      number_members_card3: "270",
      image_members_card4: "../../dvgw-icon-members-4.svg",
      text_members_card4: "persönliche Mitglieder",
      number_members_card4: "9700",                  
      title_about_membership2: "Gemeinsam Verantwortung übernehmen",
      billboard_about_membership2: "../../Bild-DVGW-Mitgliedschaft-aus-Recruitinganzeigen-RZ.jpg",
      text_about_membership2: 
      "<p>Unsere Mitglieder sind unsere Stärke. Gemeinsam gestalten wir die Entwicklung unseres Vereins und unserer Branche.</p><p style='margin-bottom:1em;'><strong>Gute Gründe für eine Mitgliedschaft im DVGW:</strong></p><ul class='arrow-list' style='margin-bottom:3em;'><li>Profitieren Sie von persönlichen und beruflichen Kontakten</li><li>Nutzen Sie unser vielseitiges Beratungs- und Informationsangebot vor Ort</li><li>Erhalten Sie Zugang zu aktuellem und praxisorientiertem Fachwissen</li><li>Gestalten und stärken Sie mit uns gemeinsam die Branche</li></ul><p><strong>Werden Sie Teil eines starken Netzwerks!</strong></p>",
      credit_about_membership2: {
        text: '© Jan Hosan',
        color: 'dark'
      },
      billboard_title_about_membership2: "",
      title_about_3: 'Landesgruppen/Bezirksgruppen S. 1',
      title_about_groups1: 'Landesgruppen',
      billboard_about_groups1: '/dvgw-landesgruppen-02.svg',
      list_about_groups1: [
        "Betreuung der DVGW-Mitgliedsunternehmen",
        "Mitwirkung bei Zertifizierungen und TSM-Überprüfungen",
        "Schnittstelle zu Landesministerien und -behörden",
        "Ansprechpartner für die Bezirksgruppen"
      ],
      text_about_groups1: "Mehr Informationen:<br/>www.dvgw.de/landesgruppen <img class='qr-sm' src='/qr/qr-about-groups1.svg'/>",
      title_about_groups2: 'Bezirksgruppen',
      billboard_about_groups2: '/dvgw-bezirksgruppen-02.svg',
      list_about_groups2: [
        "Betreuung der persönlichen Mitglieder",
        "Organisation von Veranstaltungen und Exkursionen",
        "Direkter Ansprechpartner vor Ort"
      ],
      text_about_groups2: "Mehr Informationen:<br/>www.dvgw.de/der-dvgw/organisation/landes-und-bezirksgruppen  <img class='qr-sm' src='/qr/qr-about-groups2.svg'/>",
      billboard_about_youth: "/csm_DVGW-Hochschulgruppen_9d7915b878_b.jpg",
      credit_about_youth: {
        text: '© DVGW-Hochschulgruppe Darmstadt',
        color: 'dark'
      },   
      title_about_youth: "Gemeinsam stark in die Zukunft",
      text_about_youth1: "<ul class='arrow-list'><li>Wissen teilen &amp; Kompetenzen erweitern</li><li>Zukunftsthemen mitgestalten<ul style='padding-top:1em'><li>Energie- und Wasserwende</li><li>Versorgungssicherheit</li><li>Klima- und Ressourcenschutz</ul></li><li>Ausbau des beruflichen Netzwerks</li><li>Dabei sein!</li></ul><br/><br/><p style='margin-bottom:1rem'><strong>Du möchtest weiter informiert bleiben?<br/>Besuch uns auf unserer Website und unseren Social Media Kanälen</strong></p>",
      text_about_youth2: [
        { icon: '../../Icon-Fachliche-Erfahrung-RGB.svg', text: "Sammle fachliche Erfahrungen und<br/>bringe diese in Dein Unternehmen!" },
        { icon: '../../Icon-Zukunftsthemen-RGB.svg', text: "Arbeite aktiv an den Zukunftsthemen<br/>unserer Branche!" },
        { icon: '../../Icon-Steuerungsgremien-RGB.svg', text: "Wirke in den Steuerungsgremien<br/>des Jungen DVGW mit!" },
        { icon: '../../Icon-Netzwerk-RGB.svg', text: "Bau Dir Dein eigenes Netzwerk auf!" }

      ],
      text_about_youth3: "<p>Mehr Informationen:<br/>www.dvgw.de/junger-dvgw <img class='qr-sm' src='/qr/Junger-DVGW-qr-rz.svg'/></p>",
      image_about_youth1: "../../Logo Junger DVGW RGB.svg",
      image_about_youth2: "/logos/youth2.svg",
      image_about_youth3: "/logos/youth3.svg",
      title_about_4: 'Landesgruppen/Bezirksgruppen S. 2',
      title_about_5: 'Forschungsinstitute',
      title_about_research: 'Forschungsinstitute und<br/>-einrichtungen',
      text_about_research: 'Die DVGW Forschung umfasst Projekte im regionalen und nationalen Kontext ebenso wie europaweite Forschungskooperationen.\nSie ist dabei Basis für die technische Weiterentwicklung in der Energie- und Wasserwirtschaft, fördert die Regelsetzung und Normung und sichert die wissenschaftliche Qualität der Stellungnahmen des DVGW.',
      modal_about_research: [
        { 
          id: 8,
          title: "DVGW-Forschungsstelle TUHH - Außenstelle des TZW an der Technischen Universität Hamburg",
          list: ["Praxisnahe Forschung","Optimierung der Wasseraufbereitung","Beratung von Wasserversorgungs- und Industrieunternehmen"],
          text: "Institut für Wasserressourcen und Wasserversorgung<br/>Standort: Hamburg<br/>www.dvgw.de/leistungen/forschung/institute/tzw-hamburg",
          logo: '/research/8-logo.svg',
          qr: '/research/8-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 5,
          title: "Gas- und Wärme-Institut Essen e.V. (GWI)",
          list: ["Schwerpunkte liegen auf Gas- und Wärmetechnik","Prüfung und Zertifizierung zusammen mit dem DVGW","Beratung und Hilfestellungen für Firmen im Wärmemarkt"],
          text: "Standort: Essen<br/>www.dvgw.de/leistungen/forschung/institute/gwi",
          logo: '/research/5-logo.svg',
          qr: '/research/5-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 9,
          title: "IWW Rheinisch-Westfälisches Institut für Wasserforschung gGmbH",
          list: ["Schwerpunkt liegt auf der Wasserforschung regional und europaweit","TSM Überprüfung in Verbindung mit dem DVGW","Beratung und Weiterbildung in der Wasserversorgung"],
          text: "Standort: Mülheim an der Ruhr<br/>www.dvgw.de/leistungen/forschung/institute/iww",
          logo: '/research/9-logo.svg',
          qr: '/research/9-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 1,
          title: "DVGW-Hauptgeschäftsstelle",
          list: ["Technologie und Innovationsmanagement"],
          text: "Standort: Bonn<br/>www.dvgw.de",
          logo: '/research/1-logo.svg',
          qr: '/research/1-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 2,
          title: "DVGW-Forschungsstelle am Engler-Bunte-Institut (EBI)<br/>des Karlsruher Instituts für Technologie (KIT)",
          list: ["Gastechnologie, Gasanwendung sowie Wasserchemie und Wassertechnologie","Prüflaboratorium Gas und Wassertechnologie","Prüfung und Überwachung","Zertifizierung in Verbindung mit dem DVGW","Beratung von Firmen des Gas- und Wasserfachs"],
          text: "Standort: Karlsruhe<br/>www.dvgw.de/leistungen/forschung/institute/ebi-gas/<br/>www.dvgw.de/leistungen/forschung/institute/ebi-wasser/",
          logo: '/research/2-logo.svg',
          qr: '/research/2-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 6,
          title: "DVGW-Technologiezentrum Wasser (TZW)",
          list: ["Schwerpunkte liegen auf Themen rund um das Thema Wasser","Zertifizierung in Kooperation mit dem DVGW","Entwicklung von Lösungen für die nationale und internationale Wasserbranche","Weltweite Einbindung in Forschungs- und Wissenscluster"],
          text: "Standort: Karlsruhe<br/>www.dvgw.de/leistungen/forschung/institute/tzw-karlsruhe",
          logo: '/research/6-logo.svg',
          qr: '/research/6-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 3,
          title: "DBI Gas- und Umwelttechnik GmbH (DBI GUT)",
          list: ["Gasanwendung, -förderung und -chemie","Beratung von Firmen im Gasfach"],
          text: "Standort: Leipzig<br/>www.dvgw.de/leistungen/forschung/institute/dbi-gut",
          logo: '/research/3-logo.svg',
          qr: '/research/3-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 4,
          title: "DBI Gastechnologisches Institut gGmbH (DBI-GTI)",
          list: ["Schwerpunkt liegt auf Gastechnologie und dem Prüflaboratorium Energie","Zertifizierung in Verbindung mit dem DVGW","Beratung von Firmen im Gasfach","Weiterbildungsmöglichkeiten im Gasfach"],
          text: "Standort: Freiberg<br/>www.dvgw.de/leistungen/forschung/institute/dbi-gti",
          logo: '/research/4-logo.svg',
          qr: '/research/4-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 7,
          title: "DVGW-Technologiezentrum Wasser (TZW) – Außenstelle Dresden",
          list: ["Schwerpunkt liegt auf dem Gebiet der Verteilungsnetze","Entwicklung von Lösungen","Zusammenarbeit mit Wasserversorgungsunternehmen zur Optimierung von Prozessabläufen"],
          text: "Standort: Dresden<br/>www.dvgw.de/leistungen/forschung/institute/tzw-dresden",
          logo: '/research/6-logo.svg',
          qr: '/research/7-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        }               
      ], 
      modal_about_groups: [
        { 
          id: 1,
		      title: "Landesgruppe Nord",
		      text: "Bundesländer: Schleswig-Holstein, Hamburg, Mecklenburg-Vorpommern, Niedersachsen und Bremen<br/>Standort: Hamburg",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 2,
          title: "Landesgruppe Berlin/Brandenburg",
		      text: "Bundesländer: Berlin und Brandenburg<br/>Standort: Berlin",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 3,
		      title: "Landesgruppe Mitteldeutschland",
		      text: "Bundesländer: Sachsen, Sachsen-Anhalt und Thüringen<br/>Standort: Dresden",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 4,
          title: "Landesgruppe Hessen",
		      text: "Standort: Mainz",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 5,
          title: "Landesgruppe Nordrhein-Westfalen",
		      text: "Standort: Bonn",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 6,
          title: "Landesgruppe Rheinland-Pfalz",
		      text: "Standort: Mainz",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 7,
		      title: "Landesgruppe Saarland",
	 	      text: "Standort: Saarbrücken",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 8,
          title: "DVGW-Landesgruppe Baden-Württemberg",
		      text: "Standort: Stuttgart",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 9,
          title: "Landesgruppe Bayern",
		      text: "Standort: München",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        }
      ],
      modal_activities: [
        { 
          id: 1,
          title: "Fachzeitschrift „DVGW energie | wasser praxis“",
          text: "berichtet über aktuelle technische Entwicklungen und Innovationen im Gas und Wasserfach.", 
          logo: '/dvgw-placeholder-institute-logo.svg',
          qr: '/dvgw-placeholder-qr.svg',
          type: 'see-through centered',
          list: [],
          offset: [],
          showFooter: false
        },{ 
          id: 2,
          title: "ewp Kompakt",
          text: "Eine lose Schriftenreihe zu Highlight Themen der Gas- und Wasserbranche, kompakt dargestellt",
          logo: '/dvgw-placeholder-institute-logo.svg',
          qr: '/dvgw-placeholder-qr.svg',
          type: 'see-through centered',
          list: [],
          offset: [],
          showFooter: false
        },{ 
          id: 3,
          title: "Digital",
          text: "Website, Social Media, Regelwerk Newsletter",
          logo: '/dvgw-placeholder-institute-logo.svg',
          qr: '/dvgw-placeholder-qr.svg',
          type: 'see-through centered',
          list: [],
          offset: [],
          showFooter: false
        },{ 
          id: 4,
          title: "Fachinformationen",
          text: "Broschüren, Flyer, Rundschreiben",
          logo: '/dvgw-placeholder-institute-logo.svg',
          qr: '/dvgw-placeholder-qr.svg',
          type: 'see-through centered',
          list: [],
          offset: [],
          showFooter: false
        },{ 
          id: 5,
          title: "DVGW Kongress",
          text: "Der DVGW Kongress ist  ganz nah an den relevanten Themen der Branche. Als Leitkongress der Energie- und Wasserwirtschaft ist er die jährliche zentrale Diskussions- und Informationsplattform für Fach- und Führungskräfte, Versorgungsunternehmen, Insudtrie, Forschung, Politik, Verwaltung und Kommunen.",
          logo: '/dvgw-placeholder-institute-logo.svg',
          qr: '/dvgw-placeholder-qr.svg',
          type: 'see-through centered',
          list: [],
          offset: [],
          showFooter: false
        }
      ],
      _separator_activities: 'Seite Aktivitäten',
      title_activities: "Was wir tun",
      subtitle_activities: "Unser Leistungsspektrum",
      title_activities_1: 'Regelsetzung und Normung',
      svg_labels:{
        activities1: {
          l1: 'Regelsetzung und',
          l2: 'Normung',
          p1: { x: 698.5, y: 103.549},
          p2: { x: 698.5, y: 136.049}
        },
        activities2: {
          l1: 'Forschung und',
          l2: 'Entwicklung',
          p1: { x: 862, y: 245.049},
          p2: { x: 862, y: 277.549}
        },
        activities3: {
          l1: 'Prüfung und',
          l2: 'Zertifizierung',
          p1: { x: 890, y: 440.549},
          p2: { x: 890, y: 473.049}
        },
        activities4: {
          l1: 'Berufsbildung',
          l2: 'und Qualifikation',
          p1: { x: 794.5, y: 615.5491},
          p2: { x: 794.5, y: 648.0491}
        },
        activities5: {
          l1: 'Nachwuchsförderung',
          l2: 'im DVGW',
          p1: { x: 5, y: 5},
          p2: { x: 5, y: 5}
        },
        activities6: {
          l1: 'Fachinformation',
          l2: 'und Wissenstransfer',
          p1: { x: 459.2627, y: 791.5491},
          p2: { x: 439.6123, y: 824.0491}
        },
        activities7: {
          l1: 'Sicherheits-',
          l2: 'und Qualitäts-',
          l3: 'management',
          p1: { x: 60.9502, y: 427.049},
          p2: { x: 41.9751, y: 459.549},
          p3: { x: 50.7998, y: 492.049}
        },
        activities8: {
          l1: 'Arbeits- und',
          l2: 'Gesundheitsschutz',
          p1: { x: 162.1499, y: 623.0491},
          p2: { x: 101.9751, y: 655.5491}
        },
        activities9: {
          l1: 'IT-Dienstleistungen',
          p1: { x: 26.8501, y: 268.549},
        },
        activities10: {
          l1: 'Technische',
          l2: 'Beratung',
          p1: { x: 273.9248, y: 109.049},
          p2: { x: 295.2251, y: 141.549}
        },
        technicaladvice: {
          l1: 'Beratungs-',
          l2: 'felder',
          p1: { x: 120, y: 121},
          p2: { x: 133, y: 140}
        },
        technicaladvice1: {
          l1: 'Technologie',
          l2: '',
          p1: { x: 115, y: 20},
          p2: { x: 318.2251, y: 141.549}
        },
        technicaladvice2: {
          l1: 'Technische',
          l2: 'Sicherheit',
          p1: { x: 230, y: 75},
          p2: { x: 230, y: 95}
        },
        technicaladvice3: {
          l1: 'Hygiene',
          l2: '',
          p1: { x: 230, y: 185},
          p2: { x: 318.2251, y: 141.549}
        },
        technicaladvice4: {
          l1: 'Umweltschutz',
          l2: '',
          p1: { x: 107, y: 233},
          p2: { x: 318.2251, y: 141.549}
        },
        technicaladvice5: {
          l1: 'Effizienz',
          l2: '',
          p1: { x: 20, y: 185},
          p2: { x: 318.2251, y: 141.549}
        },
        technicaladvice6: {
          l1: 'IT',
          l2: '',
          p1: { x: 50, y: 82},
          p2: { x: 318.2251, y: 141.549}
        },
        research0: {
          l1: 'Legende',
          p1: { x: 1661, y: 1300}
        },
        research1: {
          l1: 'Forschungsinstitut Gas',
          l2: '',
          p1: { x: 1661, y: 1360},
          p2: { x: 318.2251, y: 141.549}
        },
        research2: {
          l1: 'Forschungsinstitut Wasser',
          l2: '',
          p1: { x: 1661, y: 1420},
          p2: { x: 318.2251, y: 141.549}
        }                                                                
      },
      
      billboard_activities_1: '/Fotolia-131932509-XL-kerkezz_KOM.jpg',
      credit_activities_1: {
        text: '© kerkezz | fotolia',
        color: 'light'
      },
      icon_activities_1: '/icon-rulesetting-standardization.svg',
      text_activities_1: "<p>Das DVGW Regelwerk ist die Grundlage für alle, die in den Bereichen Gas und Wasser arbeiten. Der DVGW agiert unabhängig, gemeinnützig und neutral. Von Beginn an ist die Fachöffentlichkeit in den Prozess der Regelsetzung einbezogen und hat in einem transparenten Verfahren die Gelegenheit, Einsprüche und Vorschläge zu äußern.</p><h6>DVGW Regelwerk Online Plus</h6><ul class='arrow-list mb-4'><li>Regelwerke immer verfügbar</li><li>Benutzeroptimierte Suchfunktionen</li><li>nützliche Zusatzinformationen</li></ul><p>Mehr Informationen:<br/>www.dvgw-regelwerk.de <img class='qr-sm' src='/qr/qr-activities-rulesetting.svg'/></p>",
      title_activities_2: 'Forschung und Entwicklung',
      billboard_activities_2: '/iStock-1049913218-nd3000-RZ.jpg',
      credit_activities_2: {
        text: '© nd3000 | iStock',
        color: 'dark'
      },
      image_activities_2: '/mockup_regelwerk-online-plus.png',
      icon_activities_2: '/icon-research-development.svg',
      text_activities_2: "<p>Die Forschung ist im DVGW dezentral organisiert und verteilt sich auf insgesamt <strong>8 Einrichtungen</strong>. Diese vereinen wissenschaftliche Expertise und Hochschulpartnerschaften mit der Praxis der Gas und Wasserwirtschaft.</p><p>Die <strong>Energiewende fordert die Entwicklung von zukunftsweisenden Konzepten</strong> für den Energieträger Gas, unter Berücksichtigung klima- und umweltpolitischer, aber auch systemischer, wirtschaftlicher und sicherheitstechnischer Zielstellungen.</p><p>Die <strong>Wasserforschung</strong> deckt den gesamten Wasserkreislauf ab, berücksichtigt den <strong>Bevölkerungs- und Klimawandel</strong> und entwickelt technisch und wirtschaftlich nachhaltige Lösungen. Ziel ist, das hohe <strong>Qualitätsniveau und die Nachhaltigkeit der deutschen Wasserversorgung dauerhaft sicherzustellen</strong>.</p>",
      title_activities_3: 'Prüfung und Zertifizierung',
      subtitle_activities_3: 'Sicherheits-, Hygiene- und Qualitätsstandards sichern',
      billboard_activities_3: '/iStock-599142780-sturti-RZ.jpg',
      credit_activities_3: {
        text: '© sturti | iStock',
        color: 'light'
      },
      icon_activities_3: '/icon-examination-certification.svg',
      text_activities_3: '<p>Mit einer <strong>Zertifizierung</strong> durch unabhängige Stellen dokumentieren Produkthersteller, Fachunternehmen, Unternehmen der Versorgungswirtschaft sowie Sachverständige gegenüber ihren Kunden und Marktpartnern, dass der Stand der Technik eingehalten ist.</p><p class="mb-1"><strong>Eine Zertifizierung umfasst:</strong></p><ul class="arrow-list mb-4"><li>Evaluierung des Objekts</li><li>Bewertung der Ergebnisse</li><li>abschließende Bestätigung und Überwachung der Konformität</li></ul><p><strong>Neutralität, Qualität und Zuverlässigkeit</strong> haben dabei oberste Priorität.</p>',
      title_activities_4: 'Berufliche Bildung und Qualifikation',
      billboard_activities_4: '/iStock-1286013189-jacoblund-RZ.jpg',
      credit_activities_4: {
        text: '© jacoblund | iStock',
        color: 'dark'
      },      
      icon_activities_4: '/icon-training.svg',
      text_activities_4: "<p class='text-intro text-intro-lg'>Der DVGW bietet über <strong>2.100 Veranstaltungen</strong> jährlich für mehr als <strong>30.000 Teilnehmende</strong> zu mehr als <strong>350 Themen</strong> an.</p><h6>Unsere Bildungsangebote</h6><ul class='arrow-list arrow-list-narrow'><li>Masterstudiengang Netztechnik und Netzbetrieb</li><li>Meistervorbereitungslehrgang</li><li>Technische Qualifikationen mit und ohne Zertifikat</li><li>Nicht-technische Themen</li></ul><h6 class='mt-4'>Unsere Bildungsformate</h6><ul class='arrow-list arrow-list-narrow'><li>Studium</li><li>Lehrgänge</li><li>Seminare / Kurse</li><li>Workshops</li><li>Erfahrungsaustausche</li></ul>",
      title_activities_5: 'Nachwuchsförderung im DVGW',
      billboard_activities_5: '/billboard-activities-young-professionals.jpg',
      credit_activities_5: {
        text: '© Prostock-Studio | iStock',
        color: 'dark'
      },
      icon_activities_5: '/icon-technical-information.svg',
      text_activities_5: "<div class='item-list'><div class='item-list-item'><div class='item-list-icon'><img src='/icon-round-placeholder.svg'/></div><div class='item-list-text'><p>Studienarbeiten im Gas und Wasserfach werden alle zwei Jahre mit dem <strong>DVGW Studienpreis</strong> auf dem DVGW Kongress ausgezeichnet</p></div></div><div class='item-list-item'><div class='item-list-icon'><img src='/icon-round-placeholder.svg'/></div><div class='item-list-text'><p>Die in <strong>18 DVGW-Hochschulgruppen</strong> organisierten Studierenden können an DVGW-Veranstaltungen teilnehmen und profitieren von Exkursionen, Praktika in der Industrie, beruflichen Netzwerken und dem Austausch mit erfahrenen Mitgliedern</p></div></div><div class='item-list-item'><div class='item-list-icon'><img src='/icon-round-placeholder.svg'/></div><div class='item-list-text'><p>Das <strong>Young Professional Programm</strong><br/><strong>„Start Now“</strong> umfasst den Austausch im bundesweiten Netzwerk, Mentoring, Weiterbildung bei nicht-technischen Fachseminaren der DVGW Beruflichen Bildung und Mitgestaltungsmöglichkeiten in Gremien und Arbeitskreisen</p></div></div></div>",      
      title_activities_6: 'Fachinformation und Wissenstransfer',
      billboard_activities_6: '/mockup-medien.jpg',
      credit_activities_6: {
        text: '© TheCrimsonRibbon | iStock',
        color: 'light'
      },  
      icon_activities_6: '/icon-technical-information.svg',
      text_activities_6: "<p><strong>Vom Fachbuch bis zu nützlichen Broschüren für Verbraucher oder Feuerwehrleute – der DVGW gibt viele Publikationen heraus.</strong></p><p>Eine eigene Mitgliederzeitschrift, die DVGW energie | wasserpraxis, berichtet über Schwerpunkthemen, Trends und Entwicklungen im Gas- und Wasserfach.</p><p>Digital informiert der DVGW über seine Website, verschiedene Newsletter und Social Media.</p>",      
      title_activities_7: 'Sicherheits- und Qualitätsmanagement',
      billboard_activities_7: '/iStock-490715578-portra-RZ.jpg',
      credit_activities_7: {
        text: '© portra | iStock',
        color: 'dark'
      },  
      icon_activities_7: '/icon-safety-quality-management.svg',
      text_activities_7: "<p>Mit unserem <strong>technischen Sicherheitsmanagement (TSM)</strong> bieten wir ein System der freiwilligen Selbsteinschätzung und Überprüfung zur <strong>rechtsicheren Organisation Ihrer Prozesse</strong>.</p><h6>Vorteile</h6><ul class='arrow-list'><li>Organisationsverschulden vermeiden</li><li>Rechtssicherheit und im Schadensfall Beweislast umkehren</li><li>Effiziente Prozesse- und Ablauforganisation</li><li>Mit TSM sicher durch jede Krise</li><li>Aus der Praxis für die Praxis</li><li>Wettbewerbsvorteile bei Ausschreibungen</li><li>Transparenz und Wissenstransfer im Unternehmen</li></ul>",      
      image_activities_7: "/DVGW-2809-TSM-Grafik-Vorteile.png",
      title_activities_8: 'Arbeits- und Gesundheitsschutz',
      subtitle_activities_8: 'Prävention als Strategie',
      billboard_activities_8: '/RH-090219-113-RZ.jpg',
      credit_activities_8: {
        text: '© DVGW',
        color: 'light'
      },  
      icon_activities_8: '/icon-occupational-health-safety.svg',
      text_activities_8: "<p>Ein sicheres und gesundes Arbeitsumfeld ist eine Grundvoraussetzung für den wirtschaftlichen Erfolg Ihres Unternehmens. Denn ein effektiver Arbeits- und Gesundheitsschutz verhindert Ausfälle von Mitarbeitern. Die <strong>DVGW S&C GmbH</strong> bietet branchenspezifische Unterstützung, um betriebsbedingte Risiken zu minimieren und Unternehmensprozesse zu optimieren.</p><ul class='arrow-list'><li>Schulungen Arbeitssicherheit</li><li>Grundbetreuung gemäß Arbeitssicherheitsgesetz</li><li>Gefährdungsbeurteilung</li><li>Beratung zum Betriebs- und Organisationshandbuch</li></ul>",      
      title_activities_9: 'IT-Dienstleistungen',
      subtitle_activities_9: 'Sicher im Netz agieren',
      billboard_activities_9: '/iStock-957195804_obradovic-RZ.jpg',
      credit_activities_9: {
        text: '© obradovic | iStock',
        color: 'light'
      },  
      icon_activities_9: '/icon-occupational-health-safety.svg',
      text_activities_9: "<p>Der DVGW bietet auf die speziellen Bedürfnisse der Versorgungswirtschaft zugeschnittene Dienstleistungen an, die der Versorgungs-, Liefer- und Abrechnungssicherheit dienen:</p><ul class='arrow-list'><li>Codierungssysteme für die Marktkommunikation</li><li>Systematische Prüfungen des IT-Sicherheitskonzeptes</li><li>Optimierung und Unterstützung bei der Einführung neuer Systeme</li></ul><p>Mehr Informationen:<br/>www.dvgw-sc.de <img class='qr-sm' src='/qr/qr-activities-it-services.svg'/></p>",
      image_activities_9: '/dvgw-sc-logo.svg',
      title_activities_10: 'Technische Beratung',
      subtitle_activities_10: 'Maßgeschneidert dank Branchenkenntnis',
      billboard_activities_10: '/RH-081117-130-RZ.jpg',
      credit_activities_10: {
        text: '© DVGW',
        color: 'light'
      },  

      icon_activities_10: '/icon-technical-advice.svg',
      text_activities_10: "<p>Der DVGW und insbesondere seine Forschungsinstitute bieten spezifische und maßgeschneiderte technische Beratungsangebote an. Diese tragen zu einer hohen Qualität und Innovationskraft in der Gas- und Wasserversorgung bei.</p>",      
      image_activities_10: "/content-technical-advice.svg",
      title_water: "Wasser",
      title_water_supply: "Wasserversorgung in Zeiten des Klimawandels",
      billboard_water_climate_change_1: '/billboard-placeholder-small.jpg',
      text_water_climate_change_1: '<p class=\'text-video\'>Der <strong>Klimawandel</strong> ist bereits spürbar:<br/>In langen Trockenzeiten ohne Regen steigt der Spitzenbedarf stark an, während die Wasservorkommen zurückgehen.</p>',
      billboard_water_climate_change_2: '/billboard-placeholder-small.jpg',
      text_water_climate_change_2: '<p class=\'text-video mb-0\'>Bei <strong>extremem Starkregen</strong> müssen wir kurzfristig mit enormen Wassermassen rechnen. Eine zunehmende Flächenversiegelung erhöht zusätzlich das Risiko von Überschwemmungen.</p>',
      billboard_water_climate_change_3: '/billboard-placeholder-small.jpg',
      text_water_climate_change_3: '<p class=\'text-video mb-0\'>Hinzu kommt der <strong>demographische Wandel und eine zunehmende Urbanisierung:</strong> Viele Menschen zieht es in Ballungsräume, wenige bleiben auf dem Land – sie <strong>alle brauchen eine gleich gute Wasserinfrastruktur.</strong></p>',
      text_water_climate_change_4: '<p class=\'text-video mb-1\'><strong>Kontinuierliche Investitionen</strong> in Erhalt <strong>und Erneuerung der Infrastruktur</strong> sind dringend notwendig, z.B.</p><ul class=\'arrow-list arrow-list-large\'><li>Wassernetze ertüchtigen</li><li>Trinkwasserbehälter bauen</li><li>neue Wasserressourcen erschließen</li></ul>',
      text_water_climate_change_5: '<p class=\'text-video mb-0\'><strong>Politik, Behörden und Wasserversorger müssen gemeinsam handeln, damit die Wasserversorgung auch in Zukunft sicher und bezahlbar bleibt.</strong></p>',
      title_water_nitrates: "Eintrag von Nitrat, Chemikalien und Arzneimitteln",
      text_water_nitrates_1: '<p class=\'text-video text-video-lg\'><strong>Nitrat, Chemikalien und Arzneimittel stellen die Wasserversorgung vor immer größere Herausforderungen.</strong></p>',
      billboard_water_nitrates_1: '/billboard-placeholder-small.jpg',
      text_water_nitrates_2: '<p class=\'text-video mt-50\'>Damit unsere natürlichen Wasserressourcen geschützt sind, und die Trinkwasserversorgung finanziell sozialverträglich bleibt, <strong>muss der Eintrag dieser Stoffe generell verhindert werden</strong>.</p>',
      billboard_water_nitrates_2: '/billboard-placeholder-small.jpg',
      text_water_nitrates_3: '<p class=\'text-video mt-50\'><strong>Hersteller</strong> müssen für Umweltauswirkungen Ihrer Produkte aufkommen und <strong>Verursacher</strong> für Wasserverunreinigungen zur Verantwortung gezogen werden.</p>',
      billboard_water_nitrates_3: '/billboard-placeholder-small.jpg',
      text_water_responsibility: '<p class=\'text-video\'><strong>Hersteller</strong> müssen für Umweltauswirkungen Ihrer Produkte aufkommen und <strong>Verursacher</strong> von Wasserverunreinigungen <strong>zur Verantwortung</strong> gezogen werden.</p>',
      title_water_1: "Klimawandel: Infrastruktur, Vorrang der öffentlichen Wasserversorgung",
      title_water_2: "Klimawandel (2)",
      title_water_3: "Klimawandel (3)",
      title_water_4: "Klimawandel (4)",
      title_water_5: "Klimawandel (5)",
      title_water_6: "Minimierung der Einträge, Verursacherprinzip und Herstellerverantwortung",
      title_gas: "Gas",
      title_gas_1:'Problem 1',
      text_gas_1: '<p class=\'text-video\'>Erneuerbarer <strong>Strom aus Wind- und Sonnenenergie steht nur begrenzt zur Verfügung und reicht nicht aus</strong>, um den Gesamtenergieverbrauch decken zu können</p>',
      billboard_gas_1: '/billboard-placeholder-small.jpg',
      title_gas_2:'Problem 2',
      text_gas_2: '<p class=\'text-video\'>Die <strong>Elektrifizierung</strong> einzelner Sektoren ist <strong>nicht möglich</strong> (Industrie, Schwerlastverkehr) <strong>bzw. zu teuer</strong> (Wärmemarkt).</p>',
      billboard_gas_2: '/billboard-placeholder-small.jpg',
      title_gas_3:'Problem 3',
      text_gas_3: '<p class=\'text-video\'>Es sind dringend <strong>Energiespeicher nötig</strong>, wenn kein Wind weht und keine Sonne scheint.</p>',
      billboard_gas_3: '/billboard-placeholder-small.jpg',
      title_gas_4:'Lösung',
      text_gas_4: '<p class=\'text-video\'>Das <strong>Gasnetz kann die erneuerbaren Energien</strong> in Form von Gas (Power-to-Gas) <strong>speichern und transportieren</strong>.</p><p class=\'text-intro\'>Zusätzlich lassen sich klimaneutrale Gase (aus Erdgas) herstellen und ebenfalls einspeisen (Pyrolyse und Dampfreformierung).</p>',
      billboard_gas_4: '/billboard-placeholder-small.jpg',
      title_gas_5:'Wasserstoff',
      text_gas_5: '<h1>Gemeinsam (Klima)wandel gestalten.</h1><p class=\'text-video\'>Nur durch <strong>Kombination</strong> aller klimaneutralen Energien ist die Energiewende machbar:<br/><strong>Sonnen- und Windenergie, Biogas und Wasserstoff</strong> ersetzen fossile Energieträger.</p>',
      billboard_gas_5: '/billboard-placeholder-small.jpg',
      title_gas_6:'Wasserstoff: Erzeugung',
      text_gas_6: '<h1>Klimaneutral mit Wasserstoff</h1><p class=\'text-video\'>Wasserstoff ist ein zentraler Baustein für eine wirtschaftliche, umweltverträgliche und sichere Versorgung mit klimaneutraler Energie.</p>',      
      billboard_gas_6: '/billboard-placeholder-small.jpg',
      title_gas_7: 'Wasserstoff: Transport',
      text_gas_7: '<h1>Zentrale Infrastruktur<br/>der Energiewende</h1><p class=\'text-video\'>Wind- und Sonnenenergie lässt sich in Gas umwandeln und mittels der vorhandenen Infrastruktur über weite Strecken transportieren und speichern.</p>',      
      billboard_gas_7: '/billboard-placeholder-small.jpg',
      title_gas_8:'Wasserstoff: Anwendung',
      subtitle_gas_8: 'Leitungsgebundene Versorgung der Sektoren mit Wasserstoff',
      text_gas_8: '<h1>Klimaneutrale Energie<br/>für alle Sektoren.</h1><p class=\'text-video\'>Die neuen klimaneutralen Gase<br/>lassen sich in den Sektoren <strong>Wärme, Stromerzeugung, Mobilität und Industrie</strong> erfolgreich nutzen.</p>',      
      billboard_gas_8: '/billboard-placeholder-small.jpg',
      dummy_title: "Ein neuer Titel",
      dummy_text: "Lorem ipsum deutsch sit amet consectetur adipisicing elit. Praesentium accusamus asperiores facere nihil, nostrum neque voluptate impedit sapiente est debitis. Cum laborum, quaerat earum temporibus assumenda hic nesciunt amet id?",
      titles:{
        'members': "Mitglieder des DVGW",
        'members-2': "Vorteile der DVGW Mitgliedschaft"
      },
      content:{
        'members': "<b>2092</b> Unternehmen.",
        'members-2': "<ul><li>starkes Netzwerk</li><li>regionale Ansprechpartner</li><li>Know how aus erster Hand</li><li>Kostenlose Veranstaltungen und Vorträge</li></ul>"
      }
    },
    en: { 
      decimal: ",",
      title: "DVGW Titel",
      home_title: "Menu",
      button_about: "Who we are",
      button_activities: "What we do",
      button_water: "Water",
      button_gas: "Gas",
      button_toggle_language: "Deutsch",
      button_toggle_theme: "9:16",
      title_about: "Who we are",
      button_membership: "Members",
      button_groups: "Regional Offices",
      button_institutes: "Research Institutes",
      button_youngprofessionals: "Junger DVGW",
      text_about_intro: "The <strong>DVGW Deutscher Verein des Gas- und Wasserfaches e.V.</strong> promotes the gas and water industry with a focus on safety, hygiene, and environmental protection.",
      text_about_card1: "<img src='./dvgw-icon-members.svg'/><h5>More than <span>14,000</span> members</h5>",
      text_about_card2: "<img src='./dvgw-icon-network.svg'/><h5>Founded in <span>1859</span> in<br/>Frankfurt am Main</h5>",
      text_about_card3: "<img src='./dvgw-icon-gas.svg'/><h5><span>91%</span> of gas grid operators<br/>are DVGW members</h5>",
      text_about_card4: "<img src='./dvgw-icon-drinkingwater.svg'/><h5><span>73%</span> of drinking water is<br/>provided by DVGW members</h5>",
      title_about_membership1: "DVGW Members",
      image_members_card1: "../../dvgw-icon-members-1.svg",
      text_members_card1: "utility companies",
      number_members_card1: "2100",
      image_members_card2: "../../dvgw-icon-members-2.svg",
      text_members_card2: "industrial companies",
      number_members_card2: "1400",
      image_members_card3: "../../dvgw-icon-members-3.svg",
      text_members_card3: "administration",
      number_members_card3: "270",
      image_members_card4: "../../dvgw-icon-members-4.svg",
      text_members_card4: "personal members",
      number_members_card4: "9700",                  
      title_about_membership2: "Taking responsibility together",
      billboard_about_membership2: "../../Bild-DVGW-Mitgliedschaft-aus-Recruitinganzeigen-RZ.jpg",
      text_about_membership2: "<p>Our members are our strength. Together we shape the development of our association and the gas and water sector.</p><p style='margin-bottom:1em;'><strong>Good reasons for membership in the DVGW:</strong></p><ul class='arrow-list' style='margin-bottom:3em;'><li>Benefit from personal and professional contacts</li><li>Take advantage of our wide range of advice and information on site</li><li>Gain access to up-to-date and practice-oriented expertise</li><li>Shape and strengthen the gas and water sector together with us</li></ul><p><strong>Become part of a strong network!</strong></p>",
      credit_about_membership2: {
        text: '© Jan Hosan',
        color: 'dark'
      },
      billboard_title_about_membership2: "Contact us<br/>and become<br/>a DVGW<br/>member!",
      title_about_3: 'Regional Offices',
      title_about_groups1: 'Regional Offices',
      billboard_about_groups1: '/dvgw-landesgruppen-02.svg',
      list_about_groups1: [
        "Support the DVGW member companies",
        "Involved in certification and TSM inspection processes",
        "Interface with Land ministries and public authorities",
        "Point of contact for district offices"
      ],
      text_about_groups1: "For more information, please visit:<br/>www.dvgw.de/landesgruppen <img class='qr-sm' src='/qr/qr-about-groups1.svg'/>",
      title_about_groups2: 'District Offices',
      billboard_about_groups2: '/dvgw-bezirksgruppen-02.svg',
      list_about_groups2: [
        "Support individual members",
        "Organise events and excursions",
        "Personal local contact partner"
      ],
      text_about_groups2: "For more information, please visit:<br/>www.dvgw.de/der-dvgw/organisation/landes-und-bezirksgruppen  <img class='qr-sm' src='/qr/qr-about-groups2.svg'/>",
      billboard_about_youth: "/csm_DVGW-Hochschulgruppen_9d7915b878_b.jpg",
      credit_about_youth: {
        text: '© DVGW-Hochschulgruppe Darmstadt',
        color: 'dark'
      },   
      title_about_youth: "Building a Strong Future Together!",
      text_about_youth1: "<ul class='arrow-list'><li>Sharing knowledge &amp; expanding skills</li><li>Helping to shape the future<ul style='padding-top:1em'><li>Energy and water transition</li><li>Security of supply</li><li>Climate and resource protection</ul></li><li>Expanding the professional network</li><li>Be part of it!</li></ul><br/><br/><p style='margin-bottom:1rem'><strong>Would you like to stay informed?<br/>Visit us on our website and our social media channels</strong></p>",
      text_about_youth2: [
        { icon: '../../Icon-Fachliche-Erfahrung-RGB.svg', text: "Gain professional experience<br/>and bring it to your company!" },
        { icon: '../../Icon-Zukunftsthemen-RGB.svg', text: "Work actively on the future<br/>topics of our sector!" },
        { icon: '../../Icon-Steuerungsgremien-RGB.svg', text: "Participate in the steering committees<br/>of the Junge DVGW!" },
        { icon: '../../Icon-Netzwerk-RGB.svg', text: "Build up your own network!" }

      ],
      text_about_youth3: "<p>More information:<br/>www.dvgw.de/junger-dvgw <img class='qr-sm' src='/qr/Junger-DVGW-qr-rz.svg'/></p>",
      image_about_youth1: "../../Logo Junger DVGW RGB.svg",
      image_about_youth2: "/logos/youth2.svg",
      image_about_youth3: "/logos/youth3.svg",
      title_about_4: 'Landesgruppen/Bezirksgruppen S. 2',
      title_about_5: 'Forschungsinstitute',
      title_about_research: 'Research Institutes<br/>and Centres',
      text_about_research: 'The DVGW research programme covers research projects at the regional and national levels and encompasses pan-European research endeavours. It serves as a basis for promoting technical progress in the energy and water sectors, promotes the setting of rules and standards and ensures the high scientific quality of DVGW expert opinions.',
      modal_about_research: [
        { 
          id: 8,
          title: "The TUHH DVGW Research Unit - A branch of the TZ",
          list: ["Practically relevant research","Optimisation of water treatment","Water utility and industrial consultants"],
          text: "Institute of Water Resources and Water Supply<br/>Location: Hamburg<br/>www.dvgw.de/leistungen/forschung/institute/tzw-hamburg",
          logo: '/research/8-logo.svg',
          qr: '/research/8-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 5,
          title: "Gas and Heat Institute Essen e. V. (GWI)",
          list: ["Focus on gas and heat technology","Testing and certification in cooperation with the DVGW","Consultants and guidance for companies in the heat sector"],
          text: "Location: Essen<br/>www.dvgw.de/leistungen/forschung/institute/gwi",
          logo: '/research/5-logo.svg',
          qr: '/research/5-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 9,
          title: "IWW Water Centre of North Rhine-Westphalia gGmbH",
          list: ["Focus on water research in the region and across Europe","TSM inspection in cooperation with the DVGW","Water supplier consultants and providers of in-service training"],
          text: "Location: Mülheim an der Ruhr<br/>www.dvgw.de/leistungen/forschung/institute/iww",
          logo: '/research/9-logo.svg',
          qr: '/research/9-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 1,
          title: "DVGW Headquarters",
          list: ["Technology and innovation management"],
          text: "Location: Bonn<br/>www.dvgw.de",
          logo: '/research/1-logo.svg',
          qr: '/research/1-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 2,
          title: "DVGW Research Centre at the Engler-Bunte-Institute (EBI) of the Karlsruhe Institute of Technology (KIT)",
          list: ["Gas technology and gas applications, water chemistry and water technology","Test laboratory for gas and water technology","Testing and monitoring","Certification in cooperation with the DVGW","Gas and water company consultants"],
          text: "Location: Karlsruhe<br/>www.dvgw.de/leistungen/forschung/institute/ebi-gas/<br/>www.dvgw.de/leistungen/forschung/institute/ebi-wasser/",
          logo: '/research/2-logo.svg',
          qr: '/research/2-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 6,
          title: "DVGW Water Technology Centre (TZW)",
          list: ["Focus on water-related topics","Certification in cooperation with the DVGW","Solution providers for the national and international water industry","Integrated into global research and knowledge clusters"],
          text: "Location: Karlsruhe<br/>www.dvgw.de/leistungen/forschung/institute/tzw-karlsruhe",
          logo: '/research/6-logo.svg',
          qr: '/research/6-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 3,
          title: "DBI Gas and Environmental Engineering GmbH (DBI GUT)",
          list: ["Gas applications, gas production and gas chemistry","Gas company consultants"],
          text: "Location: Leipzig<br/>www.dvgw.de/leistungen/forschung/institute/dbi-gut",
          logo: '/research/3-logo.svg',
          qr: '/research/3-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 4,
          title: "DBI Institute for Gas Technology gGmbH (DBI-GTI)",
          list: ["Focus on gas technology and the energy test laboratory","Certification in cooperation with the DVGW","Gas company consultants","Advanced gas training courses"],
          text: "Location: Freiberg<br/>www.dvgw.de/leistungen/forschung/institute/dbi-gti",
          logo: '/research/4-logo.svg',
          qr: '/research/4-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        },{ 
          id: 7,
          title: "DVGW Water Technology Centre (TZW) – Dresden branch",
          list: ["Focus on distribution networks","Solution providers","Process optimisation through collaboration with water suppliers"],
          text: "Location: Dresden<br/>www.dvgw.de/leistungen/forschung/institute/tzw-dresden",
          logo: '/research/6-logo.svg',
          qr: '/research/7-qr.svg',
          type: 'see-through',
          offset: [0,0],
          showFooter: true
        }               
      ], 
      modal_about_groups: [
        { 
          id: 1,
		      title: "Northern Regional Office",
		      text: "Federal states: Schleswig-Holstein, Hamburg, Mecklenburg-Western Pomerania, Lower Saxony and Bremen<br/>Location: Hamburg",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 2,
          title: "Berlin/Brandenburg Regional Office",
		      text: "Federal states: Berlin and Brandenburg<br/>Location: Berlin",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 3,
		      title: "Central Germany Regional Office ",
		      text: "Federal states: Saxony, Saxony-Anhalt and Thuringia<br/>Location: Dresden",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 4,
          title: "Hesse Regional Office",
		      text: "Location: Mainz",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 5,
          title: "North Rhine-Westphalia Regional Office",
		      text: "Location: Bonn",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 6,
          title: "Rhineland-Palatinate Regional Office",
		      text: "Location: Mainz",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 7,
		      title: "Saarland Regional Office",
	 	      text: "Location: Saarbrücken",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 8,
          title: "DVGW Baden-Wurttemberg Regional Office",
		      text: "Location: Stuttgart",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        },{ 
          id: 9,
          title: "Bavaria Regional Office",
		      text: "Location: München",
          logo: '/research/1-logo.svg',
          qr: '/groups/qrcode-landesgruppen.svg',
          type: 'see-through',
          list: [],
          offset: [0,0],
          showFooter: false
        }
      ],
      modal_activities: [
        { 
          id: 1,
          title: "“DVGW energie | wasser praxis” technical journal",
          text: "Publication discussing the latest technical developments and innovations in the fields of gas and water", 
          logo: '/dvgw-placeholder-institute-logo.svg',
          qr: '/dvgw-placeholder-qr.svg',
          type: 'see-through centered',
          list: [],
          offset: [],
          showFooter: false
        },{ 
          id: 2,
          title: "ewp Kompakt",
          text: "Loose-leaf series highlighting topics of interest for the gas and water industry in concise form",
          logo: '/dvgw-placeholder-institute-logo.svg',
          qr: '/dvgw-placeholder-qr.svg',
          type: 'see-through centered',
          list: [],
          offset: [],
          showFooter: false
        },{ 
          id: 3,
          title: "Digital publications",
          text: "Website, social media, Newsletter ‘News on the DVGW Set of Rules’",
          logo: '/dvgw-placeholder-institute-logo.svg',
          qr: '/dvgw-placeholder-qr.svg',
          type: 'see-through centered',
          list: [],
          offset: [],
          showFooter: false
        },{ 
          id: 4,
          title: "Technical information",
          text: "Booklets, flyers, circulars",
          logo: '/dvgw-placeholder-institute-logo.svg',
          qr: '/dvgw-placeholder-qr.svg',
          type: 'see-through centered',
          list: [],
          offset: [],
          showFooter: false
        },{ 
          id: 5,
          title: "DVGW Congress",
          text: "The DVGW Congress is very close to the relevant topics of the industry. As the leading congress for the energy and water sector, it is the annual central discussion and information platform for specialists and managers, utility companies, industry, research, politics, administration and local authorities.",
          logo: '/dvgw-placeholder-institute-logo.svg',
          qr: '/dvgw-placeholder-qr.svg',
          type: 'see-through centered',
          list: [],
          offset: [],
          showFooter: false
        }
      ],
      _separator_activities: 'page activities',
      title_activities: "What we do",
      subtitle_activities: "Our services",
      title_activities_1: 'Rule setting and standardisation',
      svg_labels:{
        activities1: {
          l1: 'Rulesetting and',
          l2: 'standardisation',
          p1: { x: 698.5, y: 103.549},
          p2: { x: 698.5, y: 136.049}
        },
        activities2: {
          l1: 'Research and',
          l2: 'Development',
          p1: { x: 862, y: 245.049},
          p2: { x: 862, y: 277.549}
        },
        activities3: {
          l1: 'Testing and',
          l2: 'certification',
          p1: { x: 890, y: 440.549},
          p2: { x: 890, y: 473.049}
        },
        activities4: {
          l1: 'Vocational training',
          l2: 'and qualification',
          p1: { x: 794.5, y: 615.5491},
          p2: { x: 794.5, y: 648.0491}
        },
        activities5: {
          l1: 'Junger DVGW',
          l2: 'Programme',
          p1: { x: 5, y: 5},
          p2: { x: 5, y: 5}
        },
        activities6: {
          l1: 'Technical information and',
          l2: 'transfer of knowledge',
          p1: { x: 419.2627, y: 791.5491},
          p2: { x: 439.6123, y: 824.0491}
        },
        activities7: {
          l1: 'Safety and',
          l2: 'quality',
          l3: 'management',
          p1: { x: 70, y: 427.049},
          p2: { x: 109, y: 459.549},
          p3: { x: 50, y: 492.049}
        },
        activities8: {
          l1: 'Occupational health',
          l2: 'and safety',
          p1: { x: 90, y: 623.0491},
          p2: { x: 178, y: 655.5491}
        },
        activities9: {
          l1: 'IT services',
          p1: { x: 100, y: 268.549},
        },
        activities10: {
          l1: 'Technical',
          l2: 'consultation',
          p1: { x: 280.9248, y: 109.049},
          p2: { x: 258.9248, y: 141.549}
        },
        technicaladvice: {
          l1: 'Areas of',
          l2: 'consultation',
          p1: { x: 125, y: 121},
          p2: { x: 115, y: 140}
        },
        technicaladvice1: {
          l1: 'Technology',
          l2: '',
          p1: { x: 115, y: 20},
          p2: { x: 318.2251, y: 141.549}
        },
        technicaladvice2: {
          l1: 'Technical',
          l2: 'safety',
          p1: { x: 230, y: 75},
          p2: { x: 230, y: 95}
        },
        technicaladvice3: {
          l1: 'Hygiene',
          l2: '',
          p1: { x: 230, y: 185},
          p2: { x: 318.2251, y: 141.549}
        },
        technicaladvice4: {
          l1: 'Environmental',
          l2: 'protection',
          p1: { x: 107, y: 233},
          p2: { x: 318.2251, y: 141.549}
        },
        technicaladvice5: {
          l1: 'Efficiency',
          l2: '',
          p1: { x: 20, y: 185},
          p2: { x: 318.2251, y: 141.549}
        },
        technicaladvice6: {
          l1: 'IT',
          l2: '',
          p1: { x: 50, y: 82},
          p2: { x: 318.2251, y: 141.549}
        },
        research0: {
          l1: 'Legend',
          p1: { x: 1661, y: 1300}
        },
        research1: {
          l1: 'Gas research institute',
          l2: '',
          p1: { x: 1661, y: 1360},
          p2: { x: 318.2251, y: 141.549}
        },
        research2: {
          l1: 'Water research institute',
          l2: '',
          p1: { x: 1661, y: 1420},
          p2: { x: 318.2251, y: 141.549}
        }                                                                
      },
      
      billboard_activities_1: '/Fotolia-131932509-XL-kerkezz_KOM.jpg',
      credit_activities_1: {
        text: '© kerkezz | fotolia',
        color: 'light'
      },
      icon_activities_1: '/icon-rulesetting-standardization.svg',
      text_activities_1: "<p>The DVGW Set of Rules is the standard work for all companies involved in the gas and water sectors. The DVGW is an independent, politically neutral not-for-profit organisation. The professional public is involved in the rule-setting process from the very beginning. A transparent procedure allows them to make objections and submit suggestions.</p><h6>DVGW Set of Rules Online Plus</h6><ul class='arrow-list mb-4'><li>24/7 availability of the DVGW Set of Rules</li><li>Optimised search functions</li><li>Helpful extra information</li></ul><p>For more information, please visit <br/>www.dvgw-regelwerk.de <img class='qr-sm' src='/qr/qr-activities-rulesetting.svg'/></p>",
      title_activities_2: 'Research and Development',
      billboard_activities_2: '/iStock-1049913218-nd3000-RZ.jpg',
      credit_activities_2: {
        text: '© nd3000 | iStock',
        color: 'dark'
      },
      image_activities_2: '/mockup_regelwerk-online-plus.png',
      icon_activities_2: '/icon-research-development.svg',
      text_activities_2: "<p>DVGW Research is a distributed programme encompassing <strong>8 facilities</strong> that combine scientific knowledge and university partnerships with hands-on experience in the gas and water industry.</p><p>The <strong>energy transition requires devising viable concepts</strong> for gas as an energy carrier and having regard to the goals of climate and environmental policy while at the same time considering system, economic and technical safety objectives.</p><p><strong>Water research</strong> covers the entire water cycle, having regard to <strong>demographic and climate changes</strong> and developing sustainable technical and economic solutions, with the objective being to ensure the high <strong>quality and the sustainability of German water supply in the long term</strong>.</p>",
      title_activities_3: 'Testing and Certification',
      subtitle_activities_3: 'Ensuring safety, hygiene, and quality standards',
      billboard_activities_3: '/iStock-599142780-sturti-RZ.jpg',
      credit_activities_3: {
        text: '© sturti | iStock',
        color: 'light'
      },
      icon_activities_3: '/icon-examination-certification.svg',
      text_activities_3: '<p>Product manufacturers, technical companies, utilities, and technical experts rely on <strong>certification</strong> by independent agencies to prove to customers and market partners that their products and services are state of the art.</p><p class="mb-1"><strong>A certification process consists of:</strong></p><ul class="arrow-list mb-4"><li>Object evaluation</li><li>Evaluation of results</li><li>Final confirmation and monitoring of conformity</li></ul><p><strong>Neutrality, quality, and reliability</strong> always have top priority.</p>',
      title_activities_4: 'Vocational Training and Qualification',
      billboard_activities_4: '/iStock-1286013189-jacoblund-RZ.jpg',
      credit_activities_4: {
        text: '© jacoblund | iStock',
        color: 'dark'
      },      
      icon_activities_4: '/icon-training.svg',
      text_activities_4: "<p class='text-intro text-intro-lg'>DVGW organises over <strong>2,100 events</strong> annually for <strong>30,000 participants</strong> on more than <strong>350 topics</strong>.</p><h6>We offer the following vocational training courses</h6><ul class='arrow-list arrow-list-narrow'><li>Master course on network technology and network operation</li><li>Foreman - preparatory course </li><li>Technical qualifications, with and without certificate</li><li>Non-technical subjects</li></ul><h6 class='mt-4'>Our vocational training formats</h6><ul class='arrow-list arrow-list-narrow'><li>Degree courses</li><li>Training courses</li><li>Seminars / courses</li><li>Workshops</li><li>Exchange of experiences</li></ul>",
      title_activities_5: 'Nachwuchsförderung im DVGW',
      billboard_activities_5: '/billboard-activities-young-professionals.jpg',
      credit_activities_5: {
        text: '© Prostock-Studio | iStock',
        color: 'dark'
      },   
      icon_activities_5: '/icon-technical-information.svg',
      text_activities_5: "<div class='item-list'><div class='item-list-item'><div class='item-list-icon'><img src='/icon-round-placeholder.svg'/></div><div class='item-list-text'><p>Studienarbeiten im Gas und Wasserfach werden alle zwei Jahre mit dem <strong>DVGW Studienpreis</strong> auf der gat|wat ausgezeichnet</p></div></div><div class='item-list-item'><div class='item-list-icon'><img src='/icon-round-placeholder.svg'/></div><div class='item-list-text'><p>Die in <strong>18 DVGW-Hochschulgruppen</strong> organisierten Studierenden können an DVGW-Veranstaltungen teilnehmen und profitieren von Exkursionen, Praktika in der Industrie, beruflichen Netzwerken und dem Austausch mit erfahrenen Mitgliedern</p></div></div><div class='item-list-item'><div class='item-list-icon'><img src='/icon-round-placeholder.svg'/></div><div class='item-list-text'><p>Das <strong>Young Professional Programm</strong><br/><strong>„Start Now“</strong> umfasst den Austausch im bundesweiten Netzwerk, Mentoring, Weiterbildung bei nicht-technischen Fachseminaren der DVGW Beruflichen Bildung und Mitgestaltungsmöglichkeiten in Gremien und Arbeitskreisen</p></div></div></div>",      
      title_activities_6: 'Technical Information and Knowledge Transfer',
      billboard_activities_6: '/mockup-medien.jpg',
      credit_activities_6: {
        text: '© TheCrimsonRibbon | iStock',
        color: 'light'
      },  
      icon_activities_6: '/icon-technical-information.svg',
      text_activities_6: "<p><strong>The DVGW publishes many papers - from technical books to useful booklets for consumers or firefighters.</strong></p><p>Our members’ journal DVGW energie | wasserpraxis discusses focal topics as well as trends and developments in the gas and water sectors.</p><p>The DVGW website, several newsletters and social media also offer online information.</p>",      
      title_activities_7: 'Safety and Quality Management',
      billboard_activities_7: '/iStock-490715578-portra-RZ.jpg',
      credit_activities_7: {
        text: '© portra | iStock',
        color: 'dark'
      },  
      icon_activities_7: '/icon-safety-quality-management.svg',
      text_activities_7: "<p>Our <strong>Technical Safety Management (TSM)</strong> system is designed for voluntary use by organisations to conduct self-assessments and verify that <strong>their processes conform to legal requirements.</strong></p><h6>Benefits</h6><ul class='arrow-list'><li>Prevention of organisational culpability</li><li>Legal certainty and reversal of the burden of proof if and when necessary</li><li>Efficient workflow management and process organisation</li><li>TSM steers you safely through any crisis</li><li>From experts for experts</li><li>Competitive edge in tenders</li><li>Transparency and knowledge transfer in your organisation</li></ul>",      
      image_activities_7: "/DVGW-2809-TSM-Grafik-Vorteile.png",
      title_activities_8: 'Occupational Health and Safety',
      subtitle_activities_8: 'Prevention as strategy',
      billboard_activities_8: '/RH-090219-113-RZ.jpg',
      credit_activities_8: {
        text: '© DVGW',
        color: 'light'
      },  
      icon_activities_8: '/icon-occupational-health-safety.svg',
      text_activities_8: "<p>A healthy and safe working environment is one of the key prerequisites for a company’s economic success, for an efficient occupational health and safety system helps prevent Lost Time Injuries. The <strong>DVGW S&C GmbH</strong> offers industry-specific support to help minimise operational risks and optimise business processes.</p><ul class='arrow-list'><li>Occupational health and safety trainings</li><li>Basic assistance in accordance with the Occupational Safety Act</li><li>Hazard assessment</li><li>Consultants on operating and organisation manuals</li></ul>",      
      title_activities_9: 'IT Services',
      subtitle_activities_9: 'Safe navigation on the web',
      billboard_activities_9: '/iStock-957195804_obradovic-RZ.jpg',
      credit_activities_9: {
        text: '© obradovic | iStock',
        color: 'light'
      },  
      icon_activities_9: '/icon-occupational-health-safety.svg',
      text_activities_9: "<p>The DVGW offers services that are specifically designed to meet the needs and requirements of utilities to ensure reliable supply, delivery, and billing processes:</p><ul class='arrow-list'><li>Encoding systems for market communication</li><li>Systematic IT security concept testing</li><li>Optimisation of and support during the introduction of new systems</li></ul><p>For more information, please visit<br/>www.dvgw-sc.de <img class='qr-sm' src='/qr/qr-activities-it-services.svg'/></p>",
      image_activities_9: '/dvgw-sc-logo.svg',
      title_activities_10: 'Technical Consulting Services',
      subtitle_activities_10: 'Bespoke services thanks to our industry knowledge',
      billboard_activities_10: '/RH-081117-130-RZ.jpg',
      credit_activities_10: {
        text: '© DVGW',
        color: 'light'
      },  

      icon_activities_10: '/icon-technical-advice.svg',
      text_activities_10: "<p>The DVGW and our research institutes in particular offer specific and bespoke technical consulting services, which contribute to maintaining the superb quality and innovative power of the gas and water suppliers.</p>",      
      image_activities_10: "/content-technical-advice.svg",
      title_water: "Water",
      title_water_supply: "Water supply and climate change",
      billboard_water_climate_change_1: '/billboard-placeholder-small.jpg',
      text_water_climate_change_1: '<p class=\'text-video\'><strong>Climate change</strong> makes itself felt already now:<br/>During long periods of drought and no rainfall, peak demand soars while water supplies dwindle.</p>',
      billboard_water_climate_change_2: '/billboard-placeholder-small.jpg',
      text_water_climate_change_2: '<p class=\'text-video mb-0\'><strong>Torrential rain</strong> can produce large quantities of water in a very short time. Increased land sealing aggravates the risk of flooding further.</p>',
      billboard_water_climate_change_3: '/billboard-placeholder-small.jpg',
      text_water_climate_change_3: '<p class=\'text-video mb-0\'><strong>Demographic change and growing urbanisation</strong> are two more factors to be considered: Many people move to conurbations, while only a few remain in rural areas – but they all need a good <strong>water infrastructure.</p>',
      text_water_climate_change_4: '<p class=\'text-video mb-1\'><strong>Permanent investment</strong> in <strong>infrastructure</strong> maintenance <strong>and renewal</strong> is indispensable, e. g.</p><ul class=\'arrow-list arrow-list-large\'><li>Upgrading of water pipelines</li><li>Construction of drinking water containers</li><li>Developing new water resources</li></ul>',
      text_water_climate_change_5: '<p class=\'text-video mb-0\'><strong>Politicians, public authorities, and water suppliers need to act jointly in order to ensure the reliable and affordable supply of water in the long term.</strong></p>',
      title_water_nitrates: "Introduction of nitrates, drugs<br/>and chemicals",
      text_water_nitrates_1: '<p class=\'text-video text-video-lg\'><strong>Nitrates, chemicals, and drugs are growing challenges for our water supply.</strong></p>',
      billboard_water_nitrates_1: '/billboard-placeholder-small.jpg',
      text_water_nitrates_2: '<p class=\'text-video mt-50\'><strong>It is necessary to prevent the introduction of these substances in the first place</strong> in order to protect our natural water resources and keep the supply of drinking water affordable for everyone.</p>',
      billboard_water_nitrates_2: '/billboard-placeholder-small.jpg',
      text_water_nitrates_3: '<p class=\'text-video mt-50\'><strong>Manufacturers</strong> shall be held accountable for the environmental impact of their products, <strong>polluters</strong> for the contamination of water caused by them.</p>',
      billboard_water_nitrates_3: '/billboard-placeholder-small.jpg',
      text_water_responsibility: '<p class=\'text-video\'><strong>Hersteller</strong> müssen für Umweltauswirkungen Ihrer Produkte aufkommen und <strong>Verursacher</strong> von Wasserverunreinigungen <strong>zur Verantwortung</strong> gezogen werden.</p>',
      title_water_1: "Klimawandel: Infrastruktur, Vorrang der öffentlichen Wasserversorgung",
      title_water_2: "Klimawandel (2)",
      title_water_3: "Klimawandel (3)",
      title_water_4: "Klimawandel (4)",
      title_water_5: "Klimawandel (5)",
      title_water_6: "Minimierung der Einträge, Verursacherprinzip und Herstellerverantwortung",
      title_gas: "Gas",
      title_gas_1:'Problem 1',
      text_gas_1: '<p class=\'text-video\'>Erneuerbarer <strong>Strom aus Wind- und Sonnenenergie steht nur begrenzt zur Verfügung und reicht nicht aus</strong>, um den Gesamtenergieverbrauch decken zu können</p>',
      billboard_gas_1: '/billboard-placeholder-small.jpg',
      title_gas_2:'Problem 2',
      text_gas_2: '<p class=\'text-video\'>Die <strong>Elektrifizierung</strong> einzelner Sektoren ist <strong>nicht möglich</strong> (Industrie, Schwerlastverkehr) <strong>bzw. zu teuer</strong> (Wärmemarkt).</p>',
      billboard_gas_2: '/billboard-placeholder-small.jpg',
      title_gas_3:'Problem 3',
      text_gas_3: '<p class=\'text-video\'>Es sind dringend <strong>Energiespeicher nötig</strong>, wenn kein Wind weht und keine Sonne scheint.</p>',
      billboard_gas_3: '/billboard-placeholder-small.jpg',
      title_gas_4:'Lösung',
      text_gas_4: '<p class=\'text-video\'>Das <strong>Gasnetz kann die erneuerbaren Energien</strong> in Form von Gas (Power-to-Gas) <strong>speichern und transportieren</strong>.</p><p class=\'text-intro\'>Zusätzlich lassen sich klimaneutrale Gase (aus Erdgas) herstellen und ebenfalls einspeisen (Pyrolyse und Dampfreformierung).</p>',
      billboard_gas_4: '/billboard-placeholder-small.jpg',
      title_gas_5:'Hydrogen',
      text_gas_5: '<h1>Tackling (climate) change jointly.</h1><p class=\'text-video\'>The energy transition can be achieved only by <strong>combining</strong> all climate-neutral energies:<br/><strong>Solar and wind power, biogas and hydrogen</strong> replace fossil energy carriers.</p>',
      billboard_gas_5: '/billboard-placeholder-small.jpg',
      title_gas_6:'Hydrogen: generation',
      text_gas_6: '<h1>Climate-neutral hydrogen</h1><p class=\'text-video\'>Hydrogen is a key component on our way to achieving an economically efficient, environmentally compatible, and safe supply of climate-neutral energy.</p>',      
      billboard_gas_6: '/billboard-placeholder-small.jpg',
      title_gas_7: 'Hydrogen: transport',
      text_gas_7: '<h1>Key infrastructure<br/>of the energy transition</h1><p class=\'text-video\'>Wind and solar energy can be converted into gas and transported and stored over long distances using the existing infrastructure.</p>',      
      billboard_gas_7: '/billboard-placeholder-small.jpg',
      title_gas_8:'Hydrogen: application',
      subtitle_gas_8: 'Pipeline-bound supply of hydrogen to all sectors',
      text_gas_8: '<h1>Climate-neutral energy<br/>for all sectors</h1><p class=\'text-video\'>The new climate-neutral gases can be successfully used by the <strong>heat, power generation, mobility, and industry</strong> sectors.</p>',      
      billboard_gas_8: '/billboard-placeholder-small.jpg',
      dummy_title: "Ein neuer Titel",
      dummy_text: "Lorem ipsum deutsch sit amet consectetur adipisicing elit. Praesentium accusamus asperiores facere nihil, nostrum neque voluptate impedit sapiente est debitis. Cum laborum, quaerat earum temporibus assumenda hic nesciunt amet id?",
      titles:{
        'members': "Mitglieder des DVGW",
        'members-2': "Vorteile der DVGW Mitgliedschaft"
      },
      content:{
        'members': "<b>2092</b> Unternehmen.",
        'members-2': "<ul><li>starkes Netzwerk</li><li>regionale Ansprechpartner</li><li>Know how aus erster Hand</li><li>Kostenlose Veranstaltungen und Vorträge</li></ul>"
      }
    }
   }

   
   toggleLanguage = () => {
     this.setState({ current: (this.state.current === "de") ? "en" : "de"})
    }

  render() { 
    return (
      <LanguageContext.Provider value={{...this.state, toggleLanguage: this.toggleLanguage}}>
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}
 
export default LanguageContextProvider;